import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles.module.scss';

const UpgradeDiscountPopup = ({ onClose, onUpgrade }) => {
  return (
    <div className={styles.modal}>
      <div className={`${styles.modal_cont} ${styles.modal_cont_confirm}`}>
        <div className={styles.model_close} role="button" onClick={onClose} tabIndex={0}>
          <CloseIcon width={50} height={50} style={{ fill: '#555' }} />
          <span>close</span>
        </div>
        <div>This feature is only accessible to PRO users. To get access, upgrade your account with a TroutRoutes PRO subscription.</div>
        <div className={styles.buttons}>
          <div className={styles.button_cancel} role="button" onClick={onClose} tabIndex={0}>Cancel</div>
          <div className={styles.button_upgrade} role="button" onClick={onUpgrade} tabIndex={0}>Upgrade</div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeDiscountPopup;
