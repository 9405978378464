import React from 'react';
import { sendAmplitudeData } from 'utils/amplitude';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles.module.scss';

const ProDiscount = ({ items, onClose }) => {
  const handleClick = (url) => {
    sendAmplitudeData('web_feature_use_discount_link', { link: url });
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modal_cont}>
        <div className={styles.model_close} role="button" onClick={onClose} tabIndex={0}>
          <CloseIcon width={50} height={50} style={{ fill: '#555' }} />
          <span>close</span>
        </div>
        <h4 className={styles.modal_title}>PRO Partner Discounts</h4>
        <div>Your TroutRoutes PRO membership unlocks discounts from top partners.</div>
        <div className={styles.card_cont}>
          {items.map((item) => {
            return (
              <div className={styles.card} key={item.imgKey}>
                <img src={item.imgURL} alt="" height={32} />
                <div>{item.companyDesc}</div>
                <div className={styles.discount}>{item.promoDesc}</div>
                <a className={styles.discount_btn} href={item.shopLink} rel="noreferrer" target="_blank" onClick={() => { handleClick(item.shopLink); }}>{item.ctaBtn}</a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProDiscount;
