import { ActionTypes } from 'actions';

const initialState = {
  isAppLoaded: false,
  isAuthenticated: false,
  isRegistering: false,
  error: '',
  user: {
    email: '',
    uid: '',
    accountType: '',
    firstName: '',
    lastName: '',
  },
  accountInfo: {
    renewalDate: '',
    registrationDate: '',
  },
  trial: {
    startNotification: false,
    endNotification: false,
    endDate: '',
  },
  isPasswordModalShown: false,
  hasUpdatedAccountType: false,
  isMobileModalShown: false,
  isFlyshopMode: false,
  isFlyshopPasswordModalShown: false,
  defaultPromoCode: '',
  defaultMemberCode: '',
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
  case ActionTypes.AUTHENTICATE: {
    const { email, uid } = action.payload;
    return { ...state, isAuthenticated: true, user: { ...state.user, email, uid } };
  }
  case ActionTypes.REGISTER_USER: {
    const { email, uid } = action.payload;
    return { ...state, user: { ...state.user, email, uid } };
  }
  case ActionTypes.IS_AUTHENTICATED: {
    return { ...state, isAuthenticated: action.payload };
  }
  case ActionTypes.APP_LOAD:
    return { ...state, isAppLoaded: true };
  case ActionTypes.AUTH_ERROR:
    return { ...state, error: action.payload };
  case ActionTypes.CLEAR_AUTH_ERROR:
    return { ...state, error: '' };
  case ActionTypes.LOGOUT:
    return { ...initialState, isAppLoaded: true };
  case ActionTypes.REGISTERING:
    return { ...state, isRegistering: action.payload };
  case ActionTypes.UPDATE_USER_INFO:
    if (action?.payload?.accountType) {
      return { ...state, user: { ...state.user, ...action.payload }, hasUpdatedAccountType: true };
    } else return { ...state, user: { ...state.user, ...action.payload } };
  case ActionTypes.UPDATE_ACCOUNT_INFO:
    return { ...state, accountInfo: { ...state.accountInfo, ...action.payload } };
  case ActionTypes.SHOW_START_NOTIFICATION:
    return { ...state, trial: { ...state.trial, startNotification: action.payload.show, endDate: action.payload.endDate } };
  case ActionTypes.SHOW_END_NOTIFICATION:
    return { ...state, trial: { ...state.trial, endNotification: action.payload.show } };
  case ActionTypes.UPDATE_PASSWORD_MODAL_SHOWN:
    return { ...state, isPasswordModalShown: action.payload };
  case ActionTypes.UPDATE_MOBILE_MODAL_SHOWN:
    return { ...state, isMobileModalShown: action.payload };
  case ActionTypes.UPDATE_FLYSHOP_MODE:
    return { ...state, isFlyshopMode: action.payload };
  case ActionTypes.UPDATE_FLYSHOP_PASSWORD_MODAL_SHOWN:
    return { ...state, isFlyshopPasswordModalShown: action.payload };
  case ActionTypes.UPDATE_DEFAULT_PROMOCODE:
    return { ...state, defaultPromoCode: action.payload };
  case ActionTypes.UPDATE_DEFAULT_MEMBERCODE:
    return { ...state, defaultMemberCode: action.payload };
  default:
    return state;
  }
};

export default AuthReducer;
