import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/Image';
import styles from './styles.module.scss';

const ProfileSection = ({ title, children }) => {
  return (
    <div className={styles.section}>
      <h4 className={styles.title}>{title}</h4>
      {children}
    </div>
  );
};

ProfileSection.Label = ({ label }) => {
  return (
    <div className={styles.label}>{label}</div>
  );
};

ProfileSection.Input = ({ onChange, value, name }) => {
  return (
    <input type="text" className={styles.input} value={value} onChange={onChange} name={name} />
  );
};

ProfileSection.Link = ({ onClick, text }) => {
  return (
    <button className={`${styles.link} transparent`} onClick={onClick} type="button">{text}</button>
  );
};

ProfileSection.LinkImage = ({ onClick, text, icon }) => {
  return (
    <button className={`${styles.link} transparent ${styles.linkimage}`} onClick={onClick} type="button">
      <Image src={icon} alt={text} height={24} objectFit="cover" />
      <div>{text}</div>
    </button>
  );
};

ProfileSection.StaticText = ({ text }) => {
  return (
    <div className={styles['static-text']}>{text}</div>
  );
};

ProfileSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

ProfileSection.Label.propTypes = {
  label: PropTypes.string,
};

ProfileSection.Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

ProfileSection.Link.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

ProfileSection.StaticText.propTypes = {
  text: PropTypes.string,
};

export default ProfileSection;
