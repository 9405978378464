import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  logout, updatePasswordModalShown, setIsPaymentModalShown, setIsCancelModalShown, setisFileUploadSectionShown, setIsUpdateModalShown,
} from 'actions';
import { dollarIcon } from 'assets';
import { updateUserProfile, getDiscounts } from 'services/firebase';
import { updateRevenueCatAttributes } from 'services/stripe';
import { accountTypesEnum } from 'lib/constants';
import { sendAmplitudeData } from 'utils/amplitude';
import Pane from '../Pane';
import ImageSection from './ImageSection';
import ProfileSection from './ProfileSection';
import ProDiscount from './ProDiscount';
import UpgradeDiscountPopup from './UpgradeDiscountPopup';

const ProfilePane = () => {
  const dispatch = useDispatch();
  const [isDiscountPopup, setIsDiscountPopup] = useState(false);
  const [isUpgradePopup, setIsUpgradePopup] = useState(false);
  const [list, setList] = useState([]);

  const {
    accountType, firstName, lastName, email,
  } = useSelector((state) => state.auth.user);
  const { renewalDate, registrationDate } = useSelector((state) => state.auth.accountInfo);

  const onChange = (e) => {
    const { name, value } = e.target;
    const info = {};
    info[name] = value;
    updateUserProfile(info);
    let fullName = firstName;
    if (lastName) {
      fullName += ` ${lastName}`;
    }
    updateRevenueCatAttributes({
      email,
      displayName: fullName,
    });
  };

  const onDiscountPopup = () => {
    if (accountType !== accountTypesEnum.pro) {
      sendAmplitudeData('web_feature_use_discounts_page', { account_status: 0 });
      setIsUpgradePopup(true);
    } else {
      setIsDiscountPopup(true);
      sendAmplitudeData('web_feature_use_discounts_page', { account_status: 1 });
    }
  };

  const onUpgrade = () => {
    dispatch(setIsPaymentModalShown(true));
    setIsUpgradePopup(false);
  };

  useEffect(async () => {
    const data = await getDiscounts();
    setList(data);
  }, []);

  return (
    <>
      <Pane title="My Profile">
        <ImageSection isPro={accountType === accountTypesEnum.pro} />

        <ProfileSection title="PRO Benefits">
          <ProfileSection.LinkImage text="PRO Partner Discounts" icon={dollarIcon} onClick={() => { onDiscountPopup(); }} />
        </ProfileSection>

        <ProfileSection title="Profile">
          <ProfileSection.Label label="First Name" />
          <ProfileSection.Input name="user_firstname" value={firstName} onChange={onChange} />

          <ProfileSection.Label label="Last Name" />
          <ProfileSection.Input name="user_lastname" value={lastName} onChange={onChange} />

          <ProfileSection.Label label="Email Address" />
          <ProfileSection.Input name="email" value={email} onChange={onChange} />
        </ProfileSection>

        <ProfileSection title="Account Settings">
          <ProfileSection.Link text="Change Password" onClick={() => { dispatch(updatePasswordModalShown(true)); }} />
          <ProfileSection.Link
            text={accountType === accountTypesEnum.pro ? 'Cancel Subscription' : 'Upgrade to Pro'}
            onClick={accountType === accountTypesEnum.pro ? () => { dispatch(setIsCancelModalShown(true)); } : () => { dispatch(setIsPaymentModalShown(true)); }}
          />
          {accountType === accountTypesEnum.pro && <ProfileSection.Link text="Update Payment Info" onClick={() => dispatch(setIsUpdateModalShown(true))} />}
          {accountType === accountTypesEnum.pro && <ProfileSection.Link text="Import External Data" onClick={() => { dispatch(setisFileUploadSectionShown(true)); }} />}
          <ProfileSection.Link text="Log out" onClick={() => { dispatch(logout()); }} />
        </ProfileSection>

        <ProfileSection title="Account Details">
          <ProfileSection.Label label="Account Registration Date" />
          <ProfileSection.StaticText text={registrationDate?.split(' ')[0] || ''} />

          <ProfileSection.Label label="Account Status" />
          <ProfileSection.StaticText text={accountType.toUpperCase()} />

          {renewalDate && (
            <>
              <ProfileSection.Label label="Renewal Date" />
              <ProfileSection.StaticText text={renewalDate} />
            </>
          )}
        </ProfileSection>
      </Pane>

      {isDiscountPopup && (<ProDiscount items={list} onClose={() => { setIsDiscountPopup(false); }} />)}
      {isUpgradePopup && (<UpgradeDiscountPopup onClose={() => { setIsUpgradePopup(false); }} onUpgrade={onUpgrade} />)}
    </>
  );
};

export default ProfilePane;
