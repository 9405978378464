import React from 'react';
import Image from 'components/Image';
import PropTypes from 'prop-types';
import ThreeButtons from 'components/ThreeButtons';
import { updateStreamGeography } from 'actions';
import {
  distanceIcon, elevationIcon, closeIcon,
} from 'assets';
import CloseIcon from '@material-ui/icons/Close';
import { store } from '../../index';
import styles from './styles.module.scss';

const Popup3 = ({
  title, subtitle, type, distance, elevation, leftButton, middleButton, rightButton, iconURL, fourthButton, dispatch, color, description, typeStr, fillColor, typeInt,
}) => {
  const { tapMarker } = store.getState().map.marker;
  const onClose = async () => {
    const { map } = store.getState().map;
    const { stream } = store.getState().map;
    const { redMarker } = store.getState().map.marker;
    map.fire('closeAllPopups');
    if (tapMarker) {
      tapMarker.remove();
    }
    if (redMarker) {
      redMarker.remove();
    }
    if (stream) {
      stream.isDetail = false;
      if (dispatch) dispatch(updateStreamGeography({ isDetail: false }));
    }
    if (document.getElementsByClassName('mapboxgl-popup').length > 0) document.getElementsByClassName('mapboxgl-popup')[0].remove();
  };

  let fcolor = '';
  if (fillColor) {
    fcolor = `rgba(${fillColor.r * 255},${fillColor.g * 255},${fillColor.b * 255}, 0.9)`;
  }
  const style = {
    backgroundColor: fcolor,
  };

  return (
    <>
      {dispatch && (
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.close}
              role="button"
              onClick={onClose}
              tabIndex={0}
            >
              <CloseIcon />
              {/* <Image alt="Close" src={closeIcon} width="16" /> */}
            </div>
            <div className={styles.text}>
              <div className={styles.type}><span className={styles.circle} style={{ backgroundColor: color }} /><h6>{type}</h6></div>
              <h5>{title}</h5>
              <h6>{subtitle}</h6>
            </div>
          </div>
          <div className={styles.distances}>
            <div className={styles.distanceInner}>
              <Image alt="Distance" src={distanceIcon} width="20" />
              <p>{distance}</p>
            </div>
            <div className={styles.distanceInner}>
              <Image alt="Elevation" src={elevationIcon} width="20" />
              <p>{Number(elevation).toLocaleString('en-US', { minimumFractionDigits: 0 })} ft elev.</p>
            </div>
          </div>

          <ThreeButtons leftButton={leftButton} middleButton={middleButton} rightButton={rightButton} fourthButton={fourthButton} />
        </div>
      )}
      {!dispatch && (
        <div className={styles.popup}>
          <div className={styles.close}
            role="button"
            onClick={onClose}
            tabIndex={0}
          >
            <CloseIcon />
            {/* <Image alt="Close" src={closeIcon} width="16" /> */}
          </div>
          {!typeStr && (
            <div className={styles.content}>
              {iconURL && <Image alt={title} src={iconURL} width="8%" objectFit="contain" />}

              <div className={styles.text}>
                <h5>{title}</h5>
                <h6>{subtitle}</h6>
                {type && <p>{type}</p>}
              </div>
            </div>
          )}
          {typeStr && (
            <div className={styles.land}>
              <div className={styles.landTop}>
                <div className={styles.landIcon} style={style} />
                <div className={styles.landText}>{typeStr}</div>
              </div>
              <div className={styles.text}>
                <h5>{title}</h5>
                <h6>{subtitle}</h6>
              </div>
            </div>
          )}

          <div className={styles.distances}>
            <div className={styles.distanceInner}>
              <Image alt="Distance" src={distanceIcon} width="20" />
              <p>{distance}</p>
            </div>
            <div className={styles.distanceInner}>
              <Image alt="Elevation" src={elevationIcon} width="20" />
              <p>{Number(elevation).toLocaleString('en-US', { minimumFractionDigits: 0 })} ft elev.</p>
            </div>
          </div>
          {description && (
            <div className={`${styles.distances} ${styles.description}`}>
              <div>{description}</div>
            </div>
          )}

          <ThreeButtons leftButton={leftButton} middleButton={middleButton} rightButton={rightButton} fourthButton={fourthButton} />
        </div>
      )}
    </>
  );
};

Popup3.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  distance: PropTypes.string,
  elevation: PropTypes.string,
  leftButton: PropTypes.shape({
    label: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
  }),
  middleButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  rightButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  iconURL: PropTypes.string,
};

export default Popup3;
